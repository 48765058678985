import React, { useEffect, useRef, useState } from "react";
import Link from "next/link";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Meta from "../../components/Meta";
import { Metamask_comp_login } from "../../components/metamask/Metamask";
import Image from "next/image";
import SimpleReactValidator from 'simple-react-validator';
import requestHandler from "../../redux/httpClient";
import MySpinner from "../../components/spinner";
import { addNotification } from "../../components/Notification";
import { message } from "antd";
import { useRouter } from "next/router";

const Login = () => {
  const [itemActive, setItemActive] = useState(1);
  const simpleValidator = useRef(new SimpleReactValidator(
    {
      messages: {
        required: "This field is required.",
        min: "Password must be at least 9 characters long.",
        containsUppercase: "Password must contain at least one uppercase letter.",
        containsNumber: "Password must contain at least one number.",
        containsSpecialCharacter: "Password must contain at least one special character.",
      },
      validators: {
        containsUppercase: {
          message: "Password must contain at least one uppercase letter.",
          rule: (val) => /[A-Z]/.test(val),
        },
        containsNumber: {
          message: "Password must contain at least one number.",
          rule: (val) => /\d/.test(val),
        },
        containsSpecialCharacter: {
          message: "Password must contain at least one special character.",
          rule: (val) => /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(val),
        },
      },
    }
  ));
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [captcha, setCaptcha] = useState("");
  const [, forceUpdate] = useState("");
  const [statusCode, setStatusCode] = useState(null);
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [captchaImg, setCaptchaImg] = useState();
  const [captchaId, setCaptchaId] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [loginResponse, setLoginResponse] = useState();
  const router = useRouter();

  const tabItem = [
    {
      id: 1,
      text: "Ethereum",
      icon: "ETH",
    },
    {
      id: 2,
      text: "Torus",
      icon: "torus",
    },
    {
      id: 4,
      text: "Mobile Wallet",
      icon: "mbl-wallet",
    },
  ];

  useEffect(() => {
    getCaptchaHandler();
  }, []);

  const getCaptchaHandler = async () => {
    setLoading(true);
    let data = {
      theme: localStorage.getItem("darkMode"),
    };
    try {
      const captchaPayload = await requestHandler("createCaptcha", "post", data);
      setCaptchaId(captchaPayload?.data?.captchaId);
      setLoading(false);

      const base64data = btoa(unescape(encodeURIComponent(captchaPayload?.data?.buffer)));
      setCaptchaImg(base64data);

    }
    catch (e) {
      setLoading(false);
    };
  };

  const verifyCaptchaHandler = async () => {
    setLoading(true);
    let data = {
      code: captcha,
      id: captchaId,
    };
    try {
      const verifyCaptchaPayload = await requestHandler("verifyCaptcha", "post", data, "jwt_token");
      setLoading(false);
      return verifyCaptchaPayload;
    }
    catch (e) {
      setLoading(false);
      await getCaptchaHandler();
      addNotification({
        title: "Error",
        message: e.data.message[0].msg,
        type: "danger",
      });
    };
  };

  const loginHandler = async () => {
    setLoading(true);
    let data = {
      email: email,
      password: password,
    };
    try {
      const verifyCaptchaPayload = await verifyCaptchaHandler();
      setLoading(false);
      if (verifyCaptchaPayload.status === 200) {
        const loginPayload = await requestHandler("admin/adminLogin", "post", data);
        setLoading(false);
        setLoginResponse(loginPayload);
        if (loginPayload?.status === 200) {
          setStatusCode(200);
        }
        else if (loginPayload?.status === 201) {
          setStatusCode(201);
        }
        else if (loginPayload?.status === 202) {
          setStatusCode(202);
        }
      }
      else {
        await getCaptchaHandler();
        setLoading(false);
        setCaptcha("");
      }
    }
    catch (e) {
      setLoading(false);
      await getCaptchaHandler();
      setCaptcha("");
      addNotification({
        title: "Error",
        message: "Something went wrong",
        type: "danger",
      });
    };
  };

  const verifyEmailAddressHandler = async () => {
    setLoading(true);
    let data = {
      id: loginResponse?.data?.message?.data?.id,
      email_otp: otp,
    }
    try {
      const verifyEmailAddressPayload = await requestHandler("admin/otpVerification", "post", data, "jwt_token");
      setLoginResponse(verifyEmailAddressPayload);
      setLoading(false);
      if (verifyEmailAddressPayload.status === 200) {
        setStatusCode(200);
        setOtp("");
      }
      else {
        setStatusCode(202);
        setOtp("");
      }
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: "Error",
        message: e?.data?.message[0]?.msg,
        type: "danger",
      });
    };
  };

  const verifyTwoFactorHandler = async () => {
    setLoading(true);
    let data = {
      email: email,
      code: otp,
    };
    try {
      const verifyTwoFactorPayload = await requestHandler("admin/verifyQrCode", "post", data, "jwt_token");
      if (verifyTwoFactorPayload?.status === 200) {
        localStorage.setItem("token", verifyTwoFactorPayload?.data?.token);
      }
      setLoading(false);
      router.push("/");
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: "Error",
        message: e?.data?.message[0]?.msg,
        type: "danger",
      });
    };
  };

  return (
    <div>
      {loading && <MySpinner />}
      <Meta title="Bitdenex NFT CRM" />
      <section className="relative h-screen">
        <div className="lg:flex lg:h-full">
          <div className="relative text-center lg:w-1/2">
            <Image
              width={768}
              height={722}
              src="/images/login.gif"
              alt="login"
              className="absolute h-full w-full object-cover"
            />
          </div>

          <div className="relative flex items-center justify-center p-[10%] lg:w-1/2">
            <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
              <Image
                width={1519}
                height={773}
                priority
                src="/images/gradient_light.jpg"
                alt="gradient"
                className="h-full w-full object-cover"
              />
            </picture>
            {
              statusCode === null &&
              <div className="w-full max-w-[25.625rem]">
                <h1 className="text-jacarta-700 font-display mb-6 text-4xl dark:text-white">
                  Login
                </h1>
                <p className="dark:text-jacarta-300 mb-10 text-lg leading-normal">
                  To access your account, please enter your credentials below:
                </p>
                <div className="container">
                  <div className="mx-auto max-w-[48.125rem]">
                    <div className="mb-px">
                      <label
                        htmlFor="item-name"
                        className="font-display text-jacarta-700 mb-2 block dark:text-white"
                      >
                        Email Address <span className="text-red">*</span>
                      </label>
                      <input
                        type="text"
                        id="email-address"
                        name="email"
                        className="dark:bg-jacarta-700 border-jacarta-100 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 px-3 hover:ring-2 dark:text-white"
                        placeholder="Enter your email"
                        required
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        onBlur={() => { simpleValidator.current.showMessageFor("email"); }}
                      />
                    </div>
                    <span className="text-red">
                      {simpleValidator.current.message("email", email, "required|email")}
                    </span>
                    <div className="mb-px relative">
                      <label
                        htmlFor="item-name"
                        className="font-display text-jacarta-700 mb-2 block dark:text-white"
                      >
                        Password <span className="text-red">*</span>
                      </label>
                      <div className="relative">
                        <input
                          type={showPassword ? "text" : "password"}
                          id="password"
                          name="password"
                          className="dark:bg-jacarta-700 border-jacarta-100 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 px-3 hover:ring-2 dark:text-white"
                          placeholder="Enter your password"
                          required
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          onBlur={() => simpleValidator.current.showMessageFor("password")}
                        />
                        <div
                          className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          <span className="text-gray-400 hover:text-gray-600" role="img" aria-label="Toggle password visibility" style={{ lineHeight: '1' }}>
                            {showPassword ? '👁️‍🗨️' : '👁️'}
                          </span>
                        </div>
                      </div>
                    </div>
                    <span className="text-red">
                      {simpleValidator.current.message(
                        "password",
                        password,
                        "required|min:9|containsUppercase|containsNumber|containsSpecialCharacter"
                      )}
                    </span>
                    {captchaImg && <img src={`data:image/svg+xml;base64,${captchaImg}`} alt="captcha" />}
                    <div className="mb-px">
                      <label
                        htmlFor="item-name"
                        className="font-display text-jacarta-700 mb-2 block dark:text-white"
                      >
                        Captcha <span className="text-red">*</span>
                      </label>
                      <input
                        type="text"
                        id="Captcha"
                        name="captcha"
                        className="dark:bg-jacarta-700 border-jacarta-100 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 px-3 hover:ring-2 dark:text-white"
                        placeholder="Captcha"
                        required
                        value={captcha}
                        onChange={(e) => {
                          setCaptcha(e.target.value);
                        }}
                        onBlur={() => { simpleValidator.current.showMessageFor("captcha"); }}
                      />
                    </div>
                    <span className="text-red">
                      {simpleValidator.current.message("captcha", captcha, "required")}
                    </span>
                  </div>
                  <button
                    className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 dark:hover:bg-accent hover:bg-accent text-jacarta-700 mb-4 flex w-full items-center justify-center rounded-full border-2 bg-white py-4 px-8 text-center font-semibold transition-all hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent"
                    onClick={(e) => {
                      e.preventDefault();
                      if (simpleValidator.current.allValid()) {
                        loginHandler();
                      }
                      else {
                        simpleValidator.current.showMessages();
                        forceUpdate(1);
                      }
                    }}
                  >
                    <span>Sign in</span>
                  </button>
                </div>
              </div>
            }

            {statusCode === 200 &&
              <div className="container">
                <div className="mx-auto max-w-[48.125rem]">
                  <h1 className="text-jacarta-700 font-display mb-6 text-4xl dark:text-white">
                    Scan the below image from your Google Authenticator App.
                  </h1>
                  <p className="dark:text-jacarta-300 mb-10 text-lg leading-normal">
                    To access your account, please enter the OTP below which you have received on your app.
                  </p>
                  <img src={loginResponse?.data} alt="qrCode" />
                  <div className="mb-px">
                    <label
                      htmlFor="item-name"
                      className="font-display text-jacarta-700 mb-2 block dark:text-white"
                    >
                      OTP <span className="text-red">*</span>
                    </label>
                    <input
                      type="text"
                      id="otp"
                      name="otp"
                      className="dark:bg-jacarta-700 border-jacarta-100 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 px-3 hover:ring-2 dark:text-white"
                      placeholder="Enter your otp"
                      required
                      value={otp}
                      onChange={(e) => {
                        const regex = /^[0-9\b]+$/;
                        if (e.target.value === '' || regex.test(e.target.value)) {
                          setOtp(e.target.value)
                        }
                      }}
                      maxLength={6}
                      onBlur={() => { simpleValidator.current.showMessageFor("otp"); }}
                    />
                  </div>
                  <span className="text-red mb-6">
                    {simpleValidator.current.message("otp", otp, "required")}
                  </span>
                </div>
                <button
                  className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 dark:hover:bg-accent hover:bg-accent text-jacarta-700 mb-4 flex w-full items-center justify-center rounded-full border-2 bg-white py-4 px-8 text-center font-semibold transition-all hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent"
                  onClick={(e) => {
                    e.preventDefault();
                    if (simpleValidator.current.allValid()) {
                      verifyTwoFactorHandler();
                    }
                    else {
                      simpleValidator.current.showMessages();
                      forceUpdate(1);
                    }
                  }}
                >
                  <span>Continue</span>
                </button>
              </div>
            }
            {statusCode === 201 &&
              <div className="container">
                <div className="mx-auto max-w-[48.125rem]">
                  <h1 className="text-jacarta-700 font-display mb-6 text-4xl dark:text-white">
                    Verify your email.
                  </h1>
                  <p className="dark:text-jacarta-300 mb-10 text-lg leading-normal">
                    To access your account, please enter the OTP below which you have received on your email.
                  </p>
                  <div className="mb-px">
                    <label
                      htmlFor="item-name"
                      className="font-display text-jacarta-700 mb-2 block dark:text-white"
                    >
                      OTP <span className="text-red">*</span>
                    </label>
                    <input
                      type="text"
                      id="otp"
                      name="otp"
                      className="dark:bg-jacarta-700 border-jacarta-100 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 px-3 hover:ring-2 dark:text-white"
                      placeholder="Enter your otp"
                      required
                      value={otp}
                      onChange={(e) => {
                        const regex = /^[0-9\b]+$/;
                        if (e.target.value === '' || regex.test(e.target.value)) {
                          setOtp(e.target.value)
                        }
                      }}
                      maxLength={6}
                      onBlur={() => { simpleValidator.current.showMessageFor("otp"); }}
                    />
                  </div>
                  <span className="text-red mb-6">
                    {simpleValidator.current.message("otp", otp, "required")}
                  </span>
                </div>
                <button
                  className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 dark:hover:bg-accent hover:bg-accent text-jacarta-700 mb-4 flex w-full items-center justify-center rounded-full border-2 bg-white py-4 px-8 text-center font-semibold transition-all hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent"
                  onClick={(e) => {
                    e.preventDefault();
                    if (simpleValidator.current.allValid()) {
                      verifyEmailAddressHandler();
                    }
                    else {
                      simpleValidator.current.showMessages();
                      forceUpdate(1);
                    }
                  }}
                >
                  <span>Continue</span>
                </button>
              </div>
            }
            {statusCode === 202 &&
              <div className="container">
                <div className="mx-auto max-w-[48.125rem]">
                  <h1 className="text-jacarta-700 font-display mb-6 text-4xl dark:text-white">
                    Verify two factor authentication.
                  </h1>
                  <p className="dark:text-jacarta-300 mb-10 text-lg leading-normal">
                    To access your account, please enter the otp below which you received on your 2FA.
                  </p>
                  <div className="mb-px">
                    <label
                      htmlFor="item-name"
                      className="font-display text-jacarta-700 mb-2 block dark:text-white"
                    >
                      OTP <span className="text-red">*</span>
                    </label>
                    <input
                      type="text"
                      id="otp"
                      name="otp"
                      className="dark:bg-jacarta-700 border-jacarta-100 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 px-3 hover:ring-2 dark:text-white"
                      placeholder="Enter your otp"
                      required
                      value={otp}
                      onChange={(e) => {
                        const regex = /^[0-9\b]+$/;
                        if (e.target.value === '' || regex.test(e.target.value)) {
                          setOtp(e.target.value)
                        }
                      }}
                      maxLength={6}
                      onBlur={() => { simpleValidator.current.showMessageFor("otp"); }}
                    />
                  </div>
                  <span className="text-red mb-6">
                    {simpleValidator.current.message("otp", otp, "required")}
                  </span>
                </div>
                <button
                  className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 dark:hover:bg-accent hover:bg-accent text-jacarta-700 mb-4 flex w-full items-center justify-center rounded-full border-2 bg-white py-4 px-8 text-center font-semibold transition-all hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent"
                  onClick={(e) => {
                    e.preventDefault();
                    if (simpleValidator.current.allValid()) {
                      verifyTwoFactorHandler();
                    }
                    else {
                      simpleValidator.current.showMessages();
                      forceUpdate(1);
                    }
                  }}
                >
                  <span>Continue</span>
                </button>
              </div>
            }
          </div>
        </div>
      </section>
    </div>
  );
};

export default Login;
